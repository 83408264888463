import React, { useEffect, useRef, useState } from 'react';

const Agro5 = () => {
  const [showOverlay, setShowOverlay] = useState(true);
  const iframeRef = useRef(null);

  useEffect(() => {
    // Função para remover o overlay após alguns segundos
    const timer = setTimeout(() => {
      setShowOverlay(false);
    }, 5000); // 5 segundos

    return () => clearTimeout(timer);
  }, []);

  const handleFullScreen = () => {
    if (iframeRef.current) {
      if (iframeRef.current.requestFullscreen) {
        iframeRef.current.requestFullscreen();
      } else if (iframeRef.current.mozRequestFullScreen) { // Firefox
        iframeRef.current.mozRequestFullScreen();
      } else if (iframeRef.current.webkitRequestFullscreen) { // Chrome, Safari e Opera
        iframeRef.current.webkitRequestFullscreen();
      } else if (iframeRef.current.msRequestFullscreen) { // IE/Edge
        iframeRef.current.msRequestFullscreen();
      }
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
      {showOverlay && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 10,
            fontSize: '24px',
          }}
        >
          O domínio Agro5.com.br está à venda!
        </div>
      )}
      <iframe
        ref={iframeRef}
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/XR0OYQN4c-M?autoplay=1&mute=1"
        title="AGRO 5.0 | Tecnologia no campo"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1 }}
      ></iframe>
      <button
        onClick={handleFullScreen}
        style={{
          position: 'absolute',
          bottom: '20px',
          right: '20px',
          zIndex: 10,
          padding: '10px 20px',
          fontSize: '16px',
          cursor: 'pointer',
        }}
      >
        Tela Cheia
      </button>
    </div>
  );
};

export default Agro5;